// extracted by mini-css-extract-plugin
export var h1 = "Switch-module--h1--02261";
export var h2 = "Switch-module--h2--94e90";
export var h3 = "Switch-module--h3--ee1df";
export var h4 = "Switch-module--h4--546d0";
export var h5 = "Switch-module--h5--490c7";
export var h6 = "Switch-module--h6--3f4e4";
export var label = "Switch-module--label--0cc90";
export var listCheck = "Switch-module--list--check--27d03";
export var small = "Switch-module--small--4a832";
export var switchball = "Switch-module--switchball--174d1";
export var switchbox = "Switch-module--switchbox--bdd7d";