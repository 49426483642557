import { FC, useContext, useEffect } from "react";
import { PageContext } from "../../context/page-context";
import Dropdown from "../dropdown/dropdown";
import LinkList from "../link-list/link-list";
import "./language-switcher.scss";

export const LanguageSwitcher: FC<{
  open: boolean;
}> = ({ open }) => {
  const pageContext = useContext(PageContext);

  useEffect(() => {
    document.cookie = `firebase-country-override=${
      pageContext?.locale ?? "nl"
    };path=/;secure`;
    document.cookie = `firebase-language-override=${
      pageContext?.locale ?? "nl"
    };path=/;secure`;
  }, [pageContext?.locale]);

  return pageContext ? (
    <div className="header__language-switcher">
      {open ? (
        <LinkList
          orientation="horizontal"
          items={pageContext.links.map(([locale, link]) => ({
            active: locale === pageContext.locale,
            name: locale,
            url: link,
          }))}
        />
      ) : (
        <Dropdown title={pageContext.locale} color="clear">
          <LinkList
            orientation="vertical"
            items={pageContext.links.map(([locale, link]) => ({
              active: locale === pageContext.locale,
              name: locale,
              url: link,
            }))}
          />
        </Dropdown>
      )}
    </div>
  ) : null;
};
