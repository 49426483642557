// extracted by mini-css-extract-plugin
export var buttonContainer = "CookieConsent-module--buttonContainer--ffd35";
export var cookieLabel = "CookieConsent-module--cookieLabel--f8cda";
export var h1 = "CookieConsent-module--h1--fb2e9";
export var h2 = "CookieConsent-module--h2--ca311";
export var h3 = "CookieConsent-module--h3--31d6c";
export var h4 = "CookieConsent-module--h4--5b36e";
export var h5 = "CookieConsent-module--h5--38ac4";
export var h6 = "CookieConsent-module--h6--1e643";
export var listCheck = "CookieConsent-module--list--check--887be";
export var small = "CookieConsent-module--small--d30b9";