import classNames from "classnames";
import { FC } from "react";
import Dropdown from "../dropdown/dropdown";
import Link from "../link/link";
import "./link-list.scss";

export interface LinkItem {
  name: string;
  url: string;
  subItems?: LinkItem[];
}

interface Props {
  className?: string;
  items: LinkItem[];
  orientation: string;
}

const LinkList: FC<Props> = ({ className, items, orientation }) => {
  const linkListClassNames = classNames([
    "link-list",
    className,
    {
      "link-list--horizontal": orientation === "horizontal",
      "link-list--vertical": orientation === "vertical",
    },
  ]);

  return (
    <ul className={linkListClassNames}>
      {items.map(({ name, url, subItems }) => (
        <li
          className="link-list__item"
          key={name.replace(/\s/g, "_").toLowerCase()}
        >
          <Link
            to={url}
            className="link-list__link"
            activeClassName="link-list__link--active"
            partiallyActive
          >
            {name}
          </Link>
          {subItems?.length ? (
            <Dropdown className="link-list__dropdown" color="clear">
              {subItems.map((subItem) => (
                <Link
                  key={subItem.name.replace(/\s/g, "_").toLowerCase()}
                  className="link-list__sublink"
                  to={subItem.url}
                  activeClassName="link-list__sublink--active"
                >
                  {subItem.name}
                </Link>
              ))}
            </Dropdown>
          ) : null}
        </li>
      ))}
    </ul>
  );
};

export default LinkList;
