import { animated } from "@react-spring/web";
import { FC, ReactNode } from "react";
import ClickAwayListener from "react-click-away-listener";
import { createPortal } from "react-dom";
import { useGlobalKeyboardEventListener } from "../../hooks/useGlobalKeyboardEventListerner";
import { usePortalRootElement } from "../../hooks/usePortalRootElement";
import { useVisibilitySpring } from "../../hooks/useVisibilitySpring";
import * as styles from "./Modal.module.scss";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  children?: ReactNode;
}

const Modal: FC<ModalProps> = ({ open, onClose, children }) => {
  const { spring, visible } = useVisibilitySpring(open);
  const portalRootElement = usePortalRootElement("modal");
  useGlobalKeyboardEventListener((key) => {
    if (key === "Escape") {
      onClose();
    }
  });

  return portalRootElement && visible
    ? createPortal(
        <>
          <animated.div className={styles.backdrop} style={spring} />
          <ClickAwayListener onClickAway={onClose}>
            <animated.dialog
              open={open}
              className={styles.container}
              style={spring}
            >
              <div className={styles.modal}>
                <button className={styles.closeButton} onClick={onClose}>
                  &times;
                </button>
                {children}
              </div>
            </animated.dialog>
          </ClickAwayListener>
        </>,
        portalRootElement
      )
    : null;
};

export default Modal;
