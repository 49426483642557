import { Dispatch, SetStateAction, useEffect, useState } from "react";

export const useStorageState = <T>(
  defaultValue: T,
  { key, session = false }: { key: string; session?: boolean }
): [T, Dispatch<SetStateAction<T>>] => {
  const storage =
    typeof window !== "undefined"
      ? session
        ? window.sessionStorage
        : window.localStorage
      : undefined;
  const jsonString = storage?.getItem(key) ?? null;
  const value = jsonString && JSON.parse(jsonString);

  const [state, setState] = useState<T>(value ?? defaultValue);

  useEffect(() => {
    if (state !== undefined) {
      storage?.setItem(key, JSON.stringify(state));
    }
  }, [storage, key, state]);

  return [state, setState];
};
