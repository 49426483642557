import classNames from "classnames";
import { ButtonHTMLAttributes, FC, MouseEvent, PropsWithChildren } from "react";
import Link from "../link/link";
import "./button.scss";

interface Props {
  color?: "primary" | "secondary" | "clear";
  className?: string;
  href?: string;
  id?: string;
  loading?: boolean;
  onClick?: (ev: MouseEvent) => void;
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  variant?: string;
}

const Button: FC<PropsWithChildren<Props>> = ({
  children,
  color,
  className,
  href,
  id,
  loading,
  onClick,
  type,
  variant,
}) => {
  const buttonClassNames = classNames({
    button: true,
    "button--primary": color === "primary",
    "button--secondary": color === "secondary",
    "button--clear": color === "clear",
    "button--icon": variant === "icon",
    "button--loading": loading,
    [className || ""]: Boolean(className),
  });

  return href ? (
    <Link id={id} className={buttonClassNames} to={href}>
      {children}
    </Link>
  ) : (
    <button
      id={id}
      className={buttonClassNames}
      type={type ?? "button"}
      onClick={onClick}
      disabled={loading}
    >
      {children}
    </button>
  );
};

export default Button;
