import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { graphql } from "gatsby";
import { FC } from "react";
import { useMediaQuery } from "react-responsive";
import SmallDownIcon from "../../../../static/svg/icons/small-down.svg";
import { isDefined } from "../../../lib/is-defined";
import Link from "../../link/link";
import * as styles from "./HeaderNavigation.module.scss";

const HeaderNavigation: FC<Queries.HeaderNavigationFragment> = ({
  headerNavigation,
}) => {
  const isHorizontal = useMediaQuery({ minWidth: 960 });

  return headerNavigation?.filter(isDefined).length ? (
    <NavigationMenu.Root
      className={styles.nav}
      orientation={isHorizontal ? "horizontal" : "vertical"}
    >
      <NavigationMenu.List className={styles.list}>
        {headerNavigation
          .filter(isDefined)
          .map(({ id, sublinks, ...linkProps }) => (
            <NavigationMenu.Item key={id} className={styles.listItem}>
              {sublinks?.filter(isDefined).length ? (
                <>
                  <NavigationMenu.Trigger className={styles.subnavTrigger}>
                    <HeaderNavigationLink {...linkProps} />
                    <SmallDownIcon />
                  </NavigationMenu.Trigger>
                  <NavigationMenu.Content className={styles.subnav}>
                    <NavigationMenu.Sub orientation="vertical" asChild>
                      <NavigationMenu.List className={styles.list}>
                        {sublinks
                          .filter(isDefined)
                          .map(({ id, ...sublinkProps }) => (
                            <NavigationMenu.Item
                              key={id}
                              className={styles.listItem}
                            >
                              <HeaderNavigationLink {...sublinkProps} />
                            </NavigationMenu.Item>
                          ))}
                      </NavigationMenu.List>
                    </NavigationMenu.Sub>
                  </NavigationMenu.Content>
                </>
              ) : (
                <HeaderNavigationLink {...linkProps} />
              )}
            </NavigationMenu.Item>
          ))}
      </NavigationMenu.List>
    </NavigationMenu.Root>
  ) : null;
};

export default HeaderNavigation;

type HeaderNavigationLinkProps = Pick<
  Exclude<
    Exclude<Queries.HeaderNavigationFragment["headerNavigation"], null>[0],
    null
  >,
  "text" | "page" | "url"
>;

const HeaderNavigationLink: FC<HeaderNavigationLinkProps> = ({
  text,
  page,
  url,
}) =>
  page?.path || url ? (
    <NavigationMenu.Link asChild>
      <Link
        className={styles.link}
        to={page?.path ?? url ?? ""}
        activeClassName={styles.activeLink}
      >
        {text}
      </Link>
    </NavigationMenu.Link>
  ) : (
    text
  );

export const query = graphql`
  fragment HeaderNavigation on DatoCmsSitewide {
    headerNavigation {
      id
      text
      page {
        ... on DatoCmsPage {
          id
          path
        }
        ... on DatoCmsArticle {
          id
          path
        }
      }
      url
      sublinks {
        id
        text
        page {
          ... on DatoCmsPage {
            id
            path
          }
          ... on DatoCmsArticle {
            id
            path
          }
        }
        url
      }
    }
  }
`;
