import { graphql } from "gatsby";
import { FC, useContext } from "react";
import { PageContext } from "../../context/page-context";
import LinkList from "../link-list/link-list";
import Link from "../link/link";
import { Markdown } from "../markdown/markdown";
import "./footer.scss";

const Footer: FC<Queries.FooterFragment> = ({
  footerInfo,
  footerLogo,
  footerNavigation,
}) => {
  const pageContext = useContext(PageContext);
  const now = new Date();
  const currentYear = now.getFullYear();

  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__top">
          <div className="footer__logo">
            <Link
              to={
                pageContext?.locale === "nl" ? "/" : `/${pageContext?.locale}/`
              }
            >
              <img alt={footerLogo?.alt ?? ""} src={footerLogo?.url ?? ""} />
              <span className="visually-hidden">Securify home</span>
            </Link>
          </div>
          <div className="footer__info">
            <Markdown>{footerInfo ?? ""}</Markdown>
          </div>
        </div>
        <div className="footer__bottom">
          <nav className="footer__navigation">
            <LinkList
              orientation="horizontal"
              items={
                footerNavigation?.map((navItem) => ({
                  name: navItem?.text ?? "",
                  url: navItem?.page?.path ?? navItem?.url ?? "",
                })) ?? []
              }
            />
          </nav>
          <div className="footer__copyright">© {currentYear} Securify BV</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

export const query = graphql`
  fragment Footer on DatoCmsSitewide {
    footerLogo {
      alt
      url
    }
    footerInfo
    footerNavigation {
      id
      text
      page {
        ... on DatoCmsPage {
          id
          path
        }
        ... on DatoCmsArticle {
          id
          path
        }
      }
      url
    }
  }
`;
