import { graphql } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { FC, useState } from "react";
import Button from "../button/button";
import { Markdown } from "../markdown/markdown";
import * as styles from "./sticky-call-to-action.module.scss";

type StickyCallToActionProps = {
  stickyCallToAction:
    | Queries.StickyCallToActionFragment["stickyCallToAction"]
    | Queries.StickyCallToActionOverrideFragment["stickyCallToAction"];
};

const StickyCallToAction: FC<StickyCallToActionProps> = ({
  stickyCallToAction,
}) => {
  const [open, setOpen] = useState(true);

  return open && stickyCallToAction?.length ? (
    <div className={styles.container}>
      {stickyCallToAction?.map((fragment) => {
        if (!fragment) return null;
        const { id, title, subtitle, text, button: buttons } = fragment;
        const button = buttons && buttons[0];
        return (
          <div key={id} className={styles.part}>
            {title && <h4 className={styles.title}>{title}</h4>}
            {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
            <Markdown>{text ?? ""}</Markdown>
            {button && button.text && (button.page || button.url) ? (
              <Button
                href={button.page?.path ?? button.url ?? undefined}
                color="primary"
              >
                {button.icon?.gatsbyImageData ? (
                  <GatsbyImage
                    alt={button.icon?.alt ?? ""}
                    image={
                      button.icon.gatsbyImageData as unknown as IGatsbyImageData
                    }
                    className={styles.buttonIcon}
                  />
                ) : button.icon?.url ? (
                  <img
                    alt={button.icon.alt ?? undefined}
                    src={button.icon.url}
                    className={styles.buttonIcon}
                  />
                ) : null}
                <span>{button?.text}</span>
              </Button>
            ) : null}
          </div>
        );
      })}
      <button className={styles.closeButton} onClick={() => setOpen(false)}>
        &times;
      </button>
    </div>
  ) : null;
};

export default StickyCallToAction;

export const query = graphql`
  fragment StickyCallToActionOverride on DatoCmsPage {
    stickyCallToAction {
      id
      title
      subtitle
      text
      button {
        text
        icon {
          alt
          gatsbyImageData(width: 24, height: 24)
          url
        }
        page {
          ... on DatoCmsPage {
            id
            path
          }
          ... on DatoCmsArticle {
            id
            path
          }
        }
        url
      }
    }
  }
  fragment StickyCallToAction on DatoCmsSitewide {
    stickyCallToAction {
      id
      title
      subtitle
      text
      button {
        text
        icon {
          alt
          gatsbyImageData(width: 24, height: 24)
          url
        }
        page {
          ... on DatoCmsPage {
            id
            path
          }
          ... on DatoCmsArticle {
            id
            path
          }
        }
        url
      }
    }
  }
`;
