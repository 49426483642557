import { graphql } from "gatsby";
import { FC, useCallback, useState } from "react";
import { useSetCookieConsent } from "../../../context/CookieConsent";
import Button from "../../button/button";
import BottomBar from "../../common/BottomBar";
import Modal from "../../common/Modal";
import Switch from "../../common/Switch";
import * as styles from "./CookieConsent.module.scss";

const CookieConsent: FC<Queries.CookieConsentFragment> = ({
  description,
  acceptCookies,
  declineCookies,
  configureCookieConsent,
  googleTitle,
  googleDescription,
  hubspotTitle,
  hubspotDescription,
}) => {
  const [cookieBarOpen, setCookieBarOpen] = useState(true);
  const [cookieModalOpen, setCookieModalOpen] = useState(false);

  const [googleConsent, setGoogleConsent] = useState(true);
  const [hubspotConsent, setHubspotConsent] = useState(true);

  const anyConsent = [googleConsent, hubspotConsent].includes(true);

  const setCookieConsent = useSetCookieConsent();

  const submitCookieConsent = useCallback(() => {
    setCookieConsent({
      googleConsent,
      hubspotConsent,
      submitted: true,
    });
    setCookieBarOpen(false);
    setCookieModalOpen(false);
  }, [googleConsent, hubspotConsent, setCookieConsent]);

  const resetCookieConsent = useCallback(() => {
    setCookieModalOpen(false);
    setGoogleConsent(true);
    setHubspotConsent(true);
  }, []);

  return (
    <>
      {/* FIXME: change to cookieBarOpen */}
      <BottomBar open={false}>
        <p>{description}</p>
        <div className={styles.buttonContainer}>
          <Button onClick={() => setCookieModalOpen(true)}>
            {configureCookieConsent}
          </Button>
          <Button color="secondary" onClick={submitCookieConsent}>
            {acceptCookies}
          </Button>
        </div>
      </BottomBar>
      <Modal open={cookieModalOpen} onClose={resetCookieConsent}>
        <h3>{configureCookieConsent}</h3>

        <label className={styles.cookieLabel}>
          <Switch
            checked={googleConsent}
            onChange={(ev) => setGoogleConsent(ev.target.checked)}
          />
          {googleTitle}
        </label>
        <p>{googleDescription}</p>

        <label className={styles.cookieLabel}>
          <Switch
            checked={hubspotConsent}
            onChange={(ev) => setHubspotConsent(ev.target.checked)}
          />
          {hubspotTitle}
        </label>
        <p>{hubspotDescription}</p>

        <p>
          <Button
            color={anyConsent ? "primary" : "secondary"}
            onClick={submitCookieConsent}
          >
            {anyConsent ? acceptCookies : declineCookies}
          </Button>
        </p>
      </Modal>
    </>
  );
};

export default CookieConsent;

export const query = graphql`
  fragment CookieConsent on DatoCmsCookieConsent {
    description
    acceptCookies
    declineCookies
    configureCookieConsent
    googleTitle
    googleDescription
    hubspotTitle
    hubspotDescription
  }
`;
