import { ChangeEventHandler, FC } from "react";
import * as styles from "./Switch.module.scss";

type SwitchProps = {
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

const Switch: FC<SwitchProps> = ({ checked, onChange }) => {
  return (
    <label className={styles.label}>
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span className={styles.switchbox}>
        <span className={styles.switchball} />
      </span>
    </label>
  );
};

export default Switch;
