import { Script } from "gatsby";
import { FC } from "react";
import { useCookieConsent } from "../../../context/CookieConsent";

export const HubspotScript: FC = () => {
  const { hubspotConsent } = useCookieConsent();

  return hubspotConsent ? (
    <Script id="hs-script-loader" src="//js.hs-scripts.com/5836910.js" />
  ) : null;
};
