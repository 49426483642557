// extracted by mini-css-extract-plugin
export var activeLink = "HeaderNavigation-module--activeLink--945f0";
export var h1 = "HeaderNavigation-module--h1--9ae21";
export var h2 = "HeaderNavigation-module--h2--bf8e7";
export var h3 = "HeaderNavigation-module--h3--4d08f";
export var h4 = "HeaderNavigation-module--h4--c82df";
export var h5 = "HeaderNavigation-module--h5--0bb37";
export var h6 = "HeaderNavigation-module--h6--6d2f7";
export var link = "HeaderNavigation-module--link--9d1b6";
export var list = "HeaderNavigation-module--list--7c0d0";
export var listCheck = "HeaderNavigation-module--list--check--2b927";
export var listItem = "HeaderNavigation-module--listItem--12ae3";
export var nav = "HeaderNavigation-module--nav--a83d6";
export var slideDown = "HeaderNavigation-module--slideDown--35ce7";
export var slideUp = "HeaderNavigation-module--slideUp--12c58";
export var small = "HeaderNavigation-module--small--2e5c6";
export var subnav = "HeaderNavigation-module--subnav--d0b52";
export var subnavTrigger = "HeaderNavigation-module--subnavTrigger--71bb2";