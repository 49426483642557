import { PageProps } from "gatsby";
import { FC, ReactNode, createContext, useContext } from "react";

const LocationContext = createContext<PageProps["location"] | null>(null);

type LocationProviderProps = {
  location: PageProps["location"];
  children?: ReactNode;
};

export const LocationProvider: FC<LocationProviderProps> = ({
  location,
  children,
}) => {
  return (
    <LocationContext.Provider value={location}>
      {children}
    </LocationContext.Provider>
  );
};

export const useLocation = () => {
  const context = useContext(LocationContext);
  if (context === null)
    throw Error("useLocation should be used within a LocationProvider");
  return context;
};
