import { graphql, useStaticQuery } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import { FC } from "react";

export const FavIcon: FC = () => {
  const { datoCmsSite } = useStaticQuery<Queries.FavIconQuery>(graphql`
    query FavIcon {
      datoCmsSite {
        faviconMetaTags {
          ...GatsbyDatoCmsFaviconMetaTags
        }
      }
    }
  `);

  return datoCmsSite?.faviconMetaTags ? (
    <HelmetDatoCms favicon={datoCmsSite.faviconMetaTags} />
  ) : null;
};
