import classNames from "classnames";
import { FC, PropsWithChildren, useCallback, useRef, useState } from "react";
import SmallDownIcon from "../../../static/svg/icons/small-down.svg";
import Button from "../button/button";
import "./dropdown.scss";

interface Props {
  className?: string;
  title?: string;
  color: "primary" | "secondary" | "clear";
  variant?: string;
}

const Dropdown: FC<PropsWithChildren<Props>> = ({
  className,
  title,
  color,
  variant,
  children,
}) => {
  const [isOpen, setOpen] = useState(false);
  const toggle = useCallback((): void => setOpen(!isOpen), [isOpen]);

  const dropdownClassnames = classNames({
    [className ?? ""]: className,
    dropdown: true,
    "dropdown--open": isOpen,
  });

  const closeTimeout = useRef<number | undefined>();
  const hoverHandler = useCallback(() => {
    window.clearTimeout(closeTimeout.current);
    setOpen(true);
  }, []);
  const unhoverHandler = useCallback(() => {
    closeTimeout.current = window.setTimeout(() => setOpen(false), 400);
  }, []);

  return (
    <div
      className={dropdownClassnames}
      onMouseOver={hoverHandler}
      onMouseOut={unhoverHandler}
    >
      <Button
        className="dropdown__trigger"
        color={color}
        variant={variant}
        onClick={toggle}
      >
        {title && <span>{title}</span>}
        <SmallDownIcon />
      </Button>
      <div className="dropdown__content">{children}</div>
    </div>
  );
};

export default Dropdown;
