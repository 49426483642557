import { SpringValue, useSpring } from "@react-spring/web";
import { useState } from "react";

export const useVisibilitySpring = (
  open: boolean
): { spring: { opacity: SpringValue<number> }; visible: boolean } => {
  const [visible, setVisible] = useState(open);
  const spring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    reverse: !open,
    onStart: () => setVisible((prevVisible) => prevVisible || open),
    onResolve: () => setVisible(open),
  });

  return { spring, visible };
};
