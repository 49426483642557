import { Link as GatsbyLink, GatsbyLinkProps } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { ForwardedRef, forwardRef } from "react";

const Link = forwardRef(
  <T,>(
    { id, to, ref: gatsbyLinkRef, children, ...props }: GatsbyLinkProps<T>,
    ref: ForwardedRef<HTMLAnchorElement>
  ) => {
    const internal = /^\/(?!\/)/.test(to);

    if (internal) {
      return (
        <GatsbyLink ref={ref as null} id={id} to={to} {...props}>
          {children}
        </GatsbyLink>
      );
    }

    const {
      activeClassName,
      activeStyle,
      partiallyActive,
      replace,
      state,
      ...outboundProps
    } = props;
    return (
      <OutboundLink ref={ref as null} id={id} href={to} {...outboundProps}>
        {children}
      </OutboundLink>
    );
  }
);

export default Link;
