// extracted by mini-css-extract-plugin
export var buttonIcon = "sticky-call-to-action-module--buttonIcon--a4a91";
export var closeButton = "sticky-call-to-action-module--closeButton--b4b53";
export var container = "sticky-call-to-action-module--container--9b214";
export var h1 = "sticky-call-to-action-module--h1--fe28c";
export var h2 = "sticky-call-to-action-module--h2--bb45f";
export var h3 = "sticky-call-to-action-module--h3--b0900";
export var h4 = "sticky-call-to-action-module--h4--f1e94";
export var h5 = "sticky-call-to-action-module--h5--a18c0";
export var h6 = "sticky-call-to-action-module--h6--4c3ab";
export var listCheck = "sticky-call-to-action-module--list--check--68a69";
export var part = "sticky-call-to-action-module--part--3607a";
export var small = "sticky-call-to-action-module--small--b692c";
export var subtitle = "sticky-call-to-action-module--subtitle--32fb3";
export var text = "sticky-call-to-action-module--text--aea0b";
export var title = "sticky-call-to-action-module--title--0cf68";