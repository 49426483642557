import classNames from "classnames";
import { graphql } from "gatsby";
import { FC, useContext, useEffect, useState } from "react";
import CloseIcon from "../../../static/svg/icons/close.svg";
import MenuIcon from "../../../static/svg/icons/menu.svg";
import Logo from "../../../static/svg/logo.svg";
import { useLocation } from "../../context/LocationContext";
import { PageContext } from "../../context/page-context";
import Button from "../button/button";
import HeaderNavigation from "../dato/models/HeaderNavigation";
import { LanguageSwitcher } from "../language-switcher/language-switcher";
import Link from "../link/link";
import "./header.scss";

const Header: FC<Queries.HeaderFragment> = ({
  headerCallToAction,
  headerNavigation,
}) => {
  const pageContext = useContext(PageContext);
  const [isOpen, setOpen] = useState(false);
  const toggle = (): void => setOpen(!isOpen);

  const headerClassNames = classNames({
    header: true,
    "header--menu-open": isOpen,
  });

  const location = useLocation();
  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <header className={headerClassNames}>
      <div className="header__logo">
        {/* TODO: This should be coming from somewhere so that this locale === "nl"
        logic can be removed */}
        <Link
          to={pageContext?.locale === "nl" ? "/" : `/${pageContext?.locale}/`}
        >
          <Logo />
          <span className="visually-hidden">Securify home</span>
        </Link>
      </div>
      <button
        className="header__toggle button button--clear"
        type="button"
        onClick={toggle}
      >
        {isOpen ? <CloseIcon /> : <MenuIcon />}
        <span className="visually-hidden">Menu</span>
      </button>
      <div className="header__call-to-action">
        {headerCallToAction?.map((cta) => (
          <Button
            id={["header", cta?.id].filter(Boolean).join("-")}
            href={cta?.page?.path ?? cta?.url ?? ""}
            color="primary"
            variant="icon"
            key={cta?.id}
          >
            <img alt={cta?.icon?.alt ?? ""} src={cta?.icon?.url ?? ""} />
            <span className="visually-hidden">{cta?.text}</span>
          </Button>
        ))}
      </div>
      <HeaderNavigation headerNavigation={headerNavigation} />
      <LanguageSwitcher open={isOpen} />
    </header>
  );
};

export default Header;

export const query = graphql`
  fragment Header on DatoCmsSitewide {
    headerCallToAction {
      id
      text
      icon {
        url
        alt
      }
      page {
        ... on DatoCmsPage {
          id
          path
        }
        ... on DatoCmsArticle {
          id
          path
        }
      }
      url
    }
    ...HeaderNavigation
  }
`;
