import { graphql } from "gatsby";
import { FC, PropsWithChildren } from "react";
import { useCookieConsent } from "../../context/CookieConsent";
import CookieConsent from "../dato/models/CookieConsent";
import Footer from "../footer/footer";
import Header from "../header/header";
import StickyCallToAction from "../sticky-call-to-action/sticky-call-to-action";

import "normalize.css";
import "../../styles/basics.scss";
import "../../styles/fonts.scss";
import "../../styles/typography.scss";

export interface LayoutProps extends Queries.LayoutFragment {
  overrides: Queries.StickyCallToActionOverrideFragment;
}

const Layout: FC<PropsWithChildren<LayoutProps>> = ({
  children,
  siteWide,
  cookieConsent,
  overrides,
}) => {
  const { submitted } = useCookieConsent();
  const showCookieConsent = !submitted;

  return (
    siteWide && (
      <>
        <Header {...siteWide} />
        {children}
        <Footer {...siteWide} />
        <StickyCallToAction
          stickyCallToAction={
            overrides?.stickyCallToAction?.length
              ? overrides.stickyCallToAction
              : siteWide.stickyCallToAction
          }
        />
        {showCookieConsent && cookieConsent && (
          <CookieConsent {...cookieConsent} />
        )}
      </>
    )
  );
};

export default Layout;

export const query = graphql`
  fragment Layout on Query {
    siteWide: datoCmsSitewide(locale: $locale, fallbackLocales: ["en"]) {
      ...Header
      ...Footer
      ...StickyCallToAction
    }
    cookieConsent: datoCmsCookieConsent(
      locale: $locale
      fallbackLocales: ["en"]
    ) {
      ...CookieConsent
    }
  }
`;
