// extracted by mini-css-extract-plugin
export var backdrop = "Modal-module--backdrop--767aa";
export var closeButton = "Modal-module--closeButton--4c6d8";
export var container = "Modal-module--container--018e4";
export var h1 = "Modal-module--h1--ca78f";
export var h2 = "Modal-module--h2--e6be3";
export var h3 = "Modal-module--h3--8a035";
export var h4 = "Modal-module--h4--083f5";
export var h5 = "Modal-module--h5--b9549";
export var h6 = "Modal-module--h6--58e64";
export var listCheck = "Modal-module--list--check--948f9";
export var modal = "Modal-module--modal--2a6d7";
export var small = "Modal-module--small--12c3c";