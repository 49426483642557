import { useLayoutEffect, useState } from "react";

export const usePortalRootElement = (id: string) => {
  const [portalRootElement, setPortalRootElement] =
    useState<HTMLElement | null>(null);

  useLayoutEffect(() => {
    let el = document.getElementById(id);
    if (!el) {
      el = document.createElement("div");
      el.setAttribute("id", id);
      document.body.appendChild(el);
    }
    setPortalRootElement(el);
    return () => {
      el && document.body.removeChild(el);
    };
  }, [id]);

  return portalRootElement;
};
