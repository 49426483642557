import groovy from "highlight.js/lib/languages/groovy";
import powershell from "highlight.js/lib/languages/powershell";
import { FC } from "react";
import ReactMarkdown from "react-markdown";
import { ReactMarkdownOptions } from "react-markdown/lib/react-markdown";
import rehypeHighlight from "rehype-highlight";
import rehypeRaw from "rehype-raw";
import rehypeSlug from "rehype-slug";

import "highlight.js/styles/default.css";

export const Markdown: FC<ReactMarkdownOptions> = (props) => (
  <ReactMarkdown
    rehypePlugins={[
      rehypeRaw,
      rehypeSlug,
      [
        rehypeHighlight,
        { ignoreMissing: true, languages: { groovy, powershell } },
      ],
    ]}
    {...props}
  />
);
