import { animated } from "@react-spring/web";
import { FC, ReactNode } from "react";
import { useVisibilitySpring } from "../../hooks/useVisibilitySpring";
import * as styles from "./BottomBar.module.scss";

interface BottomBarProps {
  open: boolean;
  children?: ReactNode;
}

const BottomBar: FC<BottomBarProps> = ({ open, children }) => {
  const { spring, visible } = useVisibilitySpring(open);

  return visible ? (
    <animated.div className={styles.container} style={spring}>
      {children}
    </animated.div>
  ) : null;
};

export default BottomBar;
