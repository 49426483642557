import { GatsbyBrowser } from "gatsby";
import { HubspotScript } from "./src/components/dato/models/HubspotScript";
import { FavIcon } from "./src/components/dato/site/FavIcon";
import Layout, { LayoutProps } from "./src/components/layout/layout";
import { CookieConsentProvider } from "./src/context/CookieConsent";
import { LocationProvider } from "./src/context/LocationContext";
import { PageContext, PageContextState } from "./src/context/page-context";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => <CookieConsentProvider>{element}</CookieConsentProvider>;

export const wrapPageElement: GatsbyBrowser<
  LayoutProps,
  PageContextState
>["wrapPageElement"] = ({ element, props }) => (
  <PageContext.Provider value={props.pageContext}>
    <LocationProvider location={props.location}>
      <FavIcon />
      <HubspotScript />
      <Layout {...props.data}>{element}</Layout>
    </LocationProvider>
  </PageContext.Provider>
);
