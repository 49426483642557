import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
} from "react";
import { useStorageState } from "../hooks/useStorageState";

const COOKIE_CONSENT_KEY = "securify:cookieConsent";

type CookieConsentState = {
  googleConsent: boolean;
  hubspotConsent: boolean;
  submitted: boolean;
};

const CookieConsentContext = createContext<CookieConsentState | null>(null);
const CookieConsentDispatchContext = createContext<Dispatch<
  SetStateAction<CookieConsentState>
> | null>(null);

export const useCookieConsent = (): CookieConsentState => {
  const cookieConsent = useContext(CookieConsentContext);
  if (cookieConsent === null) {
    throw Error(
      "useCookieConsent should be used inside a CookieConsentContext."
    );
  }
  return cookieConsent;
};

export const useSetCookieConsent = (): Dispatch<
  SetStateAction<CookieConsentState>
> => {
  const setCookieConsent = useContext(CookieConsentDispatchContext);
  if (setCookieConsent === null) {
    throw Error(
      "useSetCookieConsent should be used inside a CookieConsentDispatchContext."
    );
  }
  return setCookieConsent;
};

export const CookieConsentProvider: FC<PropsWithChildren> = ({ children }) => {
  const [cookieConsent, setCookieConsent] = useStorageState<CookieConsentState>(
    // FIXME, change to
    // { googleConsent: false, hubspotConsent: false, submitted: false },
    { googleConsent: true, hubspotConsent: true, submitted: false },
    { key: COOKIE_CONSENT_KEY }
  );

  return (
    <CookieConsentContext.Provider value={cookieConsent}>
      <CookieConsentDispatchContext.Provider value={setCookieConsent}>
        {children}
      </CookieConsentDispatchContext.Provider>
    </CookieConsentContext.Provider>
  );
};
