import { useCallback, useEffect } from "react";
import { useValueRef } from "./useValueRef";

export const useGlobalKeyboardEventListener = (
  callback: (key: KeyboardEvent["key"]) => void,
  eventType: "keyup" | "keydown" | "keypress" = "keydown"
) => {
  const callbackRef = useValueRef(callback);
  const handleKeyEvent = useCallback(
    (ev: KeyboardEvent) => {
      callbackRef.current && callbackRef.current(ev.key);
    },
    [callbackRef]
  );

  useEffect(() => {
    window.addEventListener(eventType, handleKeyEvent);
    return () => window.removeEventListener(eventType, handleKeyEvent);
  }, [eventType, handleKeyEvent]);
};
